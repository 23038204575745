.Mnbr001B {
    height: 25px;
    display: flex;
    align-items: left; 
}

#Mnbr01 {
    background-color: #87CEEB;
    width: auto; /* Ensures the container takes full width */
}

#textplus {
    color: white;
    font-weight: bold;
}

#Lg-img {
    margin: 20px;
    height: 25px;
    width: 100px;
    align-items: left;
}

#Srch {
        margin-left: 10px;
        margin-top: 5px;
        height: 30px; /* Pushes the element to the right */
        margin-right: 0;
        padding: 2px;
}

#icn-001A {
         margin-left: 20px;
         margin-right: 10px;
         text-align: right;
         padding-left: 0px;
         padding-right: 10px;
         padding-top:10px;
         padding-bottom:10px;
         color: white;

}

#icn-001A:hover{ color: #6a8897}